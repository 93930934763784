<template>
  <div class="login-modal">
    <h1>{{ $t("loginModal.title") }}</h1>
    <p>{{ $t("loginModal.logInHere") }}</p>
    <div class="input-wrap">
      <div class="icon-wrap">
        <img src="@/assets/icons/user_icon.png" alt="user" />
      </div>
      <input
        type="text"
        :placeholder="$t('loginModal.usernamePlaceholder')"
        v-model="email"
        @focus="errorMsgBool = false"
      />
    </div>
    <div class="input-wrap">
      <div class="icon-wrap">
        <img src="@/assets/icons/key_icon.png" alt="key" />
      </div>
      <input
        type="password"
        :placeholder="$t('loginModal.passwordPlaceholder')"
        v-model="password"
        @focus="errorMsgBool = false"
      />
    </div>
    <div class="error-msg" v-if="errorMsgBool">
      {{ $t("loginModal.wrongCredentials") }}
    </div>
    <button @click="login">{{ $t("loginModal.loginBtn") }}</button>
  </div>
</template>

<script>
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
import api from "@/api";
import { mapState, mapActions } from "vuex";

Vue.use(VueReCaptcha, { siteKey: "6Ld-LNUbAAAAAIuYccyHMoTRZ4L6WMPsGJYDAaQu" });
export default {
  data() {
    return {
      email: "",
      password: "",
      errorMsgBool: false
    };
  },
  computed: {
    ...mapState(["sid"])
  },
  methods: {
    ...mapActions(["setSid"]),
    async login() {
      const token = await this.$recaptcha();
      try {
        const res = await api.login({
          email: this.email,
          password: this.password,
          token: token
        });
        this.setSid(res.data.data.sid);
        if (res.data.result === "OK") this.$router.push("/panel");
        console.log(res);
      } catch (error) {
        if (error.response.data.message == "Invalid credentials") {
          this.errorMsgBool = true;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.login-modal {
  padding: 50px 70px;
  width: 570px;
  height: 488px;
  background: #ffffff;
  box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .input-wrap {
    padding: 0 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    width: 429px;
    height: 65px;
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 30px;
    .icon-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      margin-right: 15px;
    }
    input {
      border: none;
      width: 100%;
      height: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 20px;
      color: $inputText;
      &:focus {
        outline: none;
      }
    }
  }
  .error-msg {
    text-align: center;
    margin-bottom: 20px;
    color: rgb(221, 41, 41);
    font-size: 15px;
  }
  p {
    margin-bottom: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.01em;
    color: $text-gray;
  }
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 32px;
    color: $light-black;
    margin-bottom: 15px;
  }
  button {
    width: 429px;
    height: 65px;
    background: $orange;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #fff;
  }
}
</style>
