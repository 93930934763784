<template>
  <div class="home-modal-toast" ref="error">
    <img src="@/assets/error.svg" alt="" />
    <h2>{{ $t("homeModalToastError.titleError") }}</h2>
    <span>
      {{ errorMsg }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    errorMsg: {
      type: String
    }
  },
  mounted() {
    this.$refs.error.scrollIntoView();
  }
};
</script>

<style scoped lang="scss">
.home-modal-toast {
  min-height: 220px;
  padding: 40px 0;
  width: 100%;
  margin: 30px auto;
  border-top: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  span {
    color: rgb(214, 59, 59);
    text-align: center;
  }
}
</style>
