import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue';
import Login from '@/views/Login.vue';
import AdminPanel from '@/views/AdminPanel.vue';
import store from '../store/index'
import Xiaomi12tClaim from '@/views/HomeClaim12t.vue';

Vue.use(VueRouter);

const routes = [
   {
    path: '/',
    name: 'Xiaomi12tClaim',
    component: Xiaomi12tClaim,
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  // },
  {
    path: '/admin-login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/panel',
    name: 'AdminPanel',
    component: AdminPanel,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
