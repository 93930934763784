<template>
  <div class="imei-wrapper">
    <div class="content">
      <div class="left">
        <img src="../../assets/xiaomi12tImages/xiaomi-grejalica.svg" />
      </div>
      <div class="right">
        <imeiForm @show-modal="showModal" @show-imei="showIMEI" />
      </div>
    </div>
  </div>
</template>

<script>
import imeiForm from "../../components/ClaimXiaomi12t/ImeiForm.vue";
export default {
  components: {
    imeiForm
  },
  methods: {
    showModal() {
      this.$emit("show-modal");
    },
    showIMEI() {
      this.$emit("show-imei");
    }
  }
  //   data() {
  //     return {};
  //   },
  //   methods: {}
};
</script>

<style scoped lang="scss">
.imei-wrapper {
  background-color: #ededed;
  .content {
    width: 100%;
    padding: 95px 0 95px 0;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .left {
      img {
        width: 100%;
        max-width: none !important;
      }
    }
    .right {
    }
  }
}

@media only screen and (max-width: 725px) {
  .imei-wrapper {
    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .left {
        img {
          display: none;
        }
      }
      .right {
      }
    }
  }
}

@media only screen and (max-width: 1166px) {
}
@media only screen and (max-width: 1080px) {
}

@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 600px) {
}
</style>
