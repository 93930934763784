<template>
  <div class="home-claim-12t-wrapper">
    <div
      class="absolute-wrapper"
      v-if="showModal || showSuccessToast || showIMEI"
    ></div>
    <div class="xiaomi-12t-kv">
      <Xiaomi12tKV />
      <!-- <ModalComponent v-if="showModal" @closeModal="closeModal" /> -->
      <ModalComponent
        v-if="showModal"
        @closeModal="closeModal"
        @showSuccess="showSuccess"
      />
    </div>
    <div class="xiaomi-12t-imei">
      <Imei @show-modal="showModalFunc" @show-imei="showImeiFunc" />
      <ImeiModal @closeIMEIModal="closeIMEImodal" v-if="showIMEI" />
    </div>
    <div class="xiaomi-12t-images">
      <XiaomiImage1 />
      <XiaomiImage2 />
      <XiaomiImage3 />
      <XiaomiImage4 />
      <XiaomiImage5 />
    </div>
  </div>
</template>

<script>
// import HomeModalComponent from "../components/HomeModalComponent.vue";
// import HomeModalToastSuccess from "../components/HomeModalToastSuccess.vue";
// import IMEImodal from "../components/IMEImodal.vue";
import Xiaomi12tKV from "../components/ClaimXiaomi12t/KVXiaomi12t.vue";
import Imei from "../components/ClaimXiaomi12t/ImeiComponent.vue";
import XiaomiImage1 from "../components/ClaimXiaomi12t/Xiaomi12tImage1.vue";
import XiaomiImage2 from "../components/ClaimXiaomi12t/Xiaomi12tImage2.vue";
import XiaomiImage3 from "../components/ClaimXiaomi12t/Xiaomi12tImage3.vue";
import XiaomiImage4 from "../components/ClaimXiaomi12t/Xiaomi12tImage4.vue";
import XiaomiImage5 from "../components/ClaimXiaomi12t/Xiaomi12tImage5.vue";
// import ModalComponent from "../components/ClaimXiaomi12t/ModalComponent.vue";
import ModalComponent from "../components/BackToSchool/HomeModalComponent.vue";
import ImeiModal from "../components/BackToSchool/IMEImodal.vue";

export default {
  components: {
    Xiaomi12tKV,
    Imei,
    XiaomiImage1,
    XiaomiImage2,
    XiaomiImage3,
    XiaomiImage4,
    XiaomiImage5,
    ModalComponent,
    ImeiModal
    // test
    // HomeModalComponent,
    // HomeModalToastSuccess,
    // IMEImodal
  },
  data() {
    return {
      showModal: false,
      showSuccessToast: false,
      showIMEI: false
    };
  },
  methods: {
    showModalFunc() {
      this.showModal = !this.showModal;
    },
    showImeiFunc() {
      this.showIMEI = !this.showIMEI;
    },
    // handleShowIMEI() {
    //   this.showIMEI = true;
    // },
    closeModal() {
      this.showModal = false;
    },
    closeIMEImodal() {
      this.showIMEI = false;
    },
    showSuccess() {}
  }
  //   data() {
  //     return {};
  //   },
  //   methods: {}
};
</script>

<style scoped lang="scss">
.home-claim-12t-wrapper {
  width: 100%;
  .absolute-wrapper {
    height: 600vw;
    width: 100%;
    background: #fff;
    opacity: 0.9;
    z-index: 10;
    position: absolute;
  }
  .xiaomi-12t-images {
    font-size: 0;
  }
}

@media only screen and (max-width: 1166px) {
}
@media only screen and (max-width: 1080px) {
}

@media only screen and (max-width: 725px) {
}
@media only screen and (max-width: 600px) {
}
</style>
