<template>
  <div class="imei-form-wrapper">
    <div class="apply-for">
      <span> Prijavite se za poklon</span> <br />
      <!-- <img src="../assets/prize.png" alt="" /> -->
    </div>
    <div class="button-container">
      <button class="choose-gift" @click="showModal">
        {{ $t("home.applyBtn") }}
      </button>
      <button class="rules" @click="showIMEI">
        Kako do IMEI broja?
      </button>
    </div>
    <div class="support-mail">
      <span>Ukoliko imate problem sa prijavom obratite nam se na</span>
      <br />
      <span>xiaomi.prijava@gmail.com</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    showModal() {
      this.$emit("show-modal");
    },
    showIMEI() {
      this.$emit("show-imei");
    }
  }
};
</script>

<style scoped lang="scss">
.imei-form-wrapper {
  .apply-for {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 121.69%;
    color: #000;
    align-self: baseline;
    margin: 0 0 100px 0;
    img {
      display: block;
      max-width: 400px;
    }
  }
  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 0 auto;
    button {
      width: 201px;
      height: 61px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-family: Xiaomi-Regular;
      border: none;
      outline: none;
      cursor: pointer;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
    }
    .choose-gift {
      background: #ff6900;
      color: #ffffff;
    }
    .rules {
      background: #fff;
      color: #ff6900;
    }
  }
  .support-mail {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    color: #4c4e52;
    span {
      display: inline-block;
      text-align: center;
    }
    span:nth-of-type(2) {
      color: #ff6900;
    }
  }
}
@media only screen and (max-width: 725px) {
  .imei-form-wrapper {
    .apply-for {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 121.69%;
      img {
        display: block;
        max-width: 400px;
      }
    }
    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      margin: 0 auto;
      button {
        width: 127.04px;
        height: 38.55px;
        font-size: 11px;
      }
      .choose-gift {
        background: #ff6900;
        color: #ffffff;
      }
      .rules {
        background: #fff;
        color: #ff6900;
      }
    }
    .support-mail {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      span {
        display: inline-block;
        text-align: center;
      }
      span:nth-of-type(2) {
        color: #ff6900;
      }
    }
  }
}
@media only screen and (max-width: 1166px) {
}
@media only screen and (max-width: 1080px) {
}

@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 600px) {
}
</style>
